<template>
  <div class="devotional">
    <section class="devotionalSection">
      <div class="col1">
        <WeekVerse />
      </div>
      <div class="col2">
        <h1 v-if="formFields && formFields.week">Semana {{ formFields.week }}</h1>
      <div v-if="formFields">
        <h3 >{{ formFields.title }}</h3>
        <p v-if="formFields.description">{{ formFields.description }}</p>

        <youtube v-if="formFields && formFields.video" class="devotionalVideo" :video-id="formFields.video"></youtube>

        <div class="devotionalItem" ref="postFields" :key="index" v-for="(field, index) in JSON.parse(formFields.questions)">
          <label v-if="field.question" :for="field.name">{{ field.question }}</label>
          <input :ref="`postInput_${field.name}`" :id="field.name" :data-question="field.question" v-if="field.type === 'text'" :name="field.name" type="text" v-debounce:3s="savePost">
          <textarea :ref="`postInput_${field.name}`" v-if="field.type === 'textarea'" :name="field.name" cols="30" rows="10" v-debounce:3s="savePost"></textarea>
          <div v-else>
            {{ field.value }}
          </div>
        </div>
      </div>
      </div>
    </section>
    <Message :show="showMessage" :message="message" />
  </div>
</template>

<script>
import WeekVerse from '@/components/WeekVerse'
import Message from '@/components/Message'
import { mapState } from 'vuex'

export default {
  components: {
    WeekVerse,
    Message
  },
  data() {
    return {
      routeId: '',
      showMessage: false,
      message: ''
    }
  },
  computed: {
    ...mapState({
      formFields: state => state.currentForm,
      answers: state => state.currentFormAnswers
    })
  },
  methods: {
    clearMessage() {
      this.showMessage = false
      this.message = ''
    },
    savePost() {
      this.showMessage = true
      this.message = 'Cambios Guardados'

      setTimeout(() => this.clearMessage(), 2000)
      const fieldsMap = this.$refs.postFields.map(field => {

        if (field.hasChildNodes()) {
          const children = field.children;

          for (let i = 0; i < children.length; i++) {
            const child = children[i]

            let isField = child.nodeName === 'INPUT' || child.nodeName === 'TEXTAREA'

            if (isField) {
              return {
                name: child.name,
                question: child.dataset.question,
                answer: child.value
              }
            }
          }
        }
      })

      const fields = JSON.stringify(fieldsMap)
      const postData = {
        fields,
        postId: this.formId
      }

      this.$store.dispatch('userPost', postData)
    }
  },
  mounted() {
    this.formId = this.$router.currentRoute.params.id

    // fetch user profile and set in state
     this.$store.dispatch('fetchForm', this.formId)
     this.$store.dispatch('fetchAnswers', this.formId)
    
    
  },
  beforeUpdate() {
    // Notify
    const fields = null;
    console.log(this.formFields);
    const postData = {
        fields,
        postId: this.$router.currentRoute.params.id,
        title: this.formFields.title,
        descrip: this.formFields.week
    }
    console.log(postData);
      this.$store.dispatch('notifyPost', postData)
  },
  watch: {
    answers(newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        const answers = JSON.parse(newVal.answers)

        answers.map(answer => {
          if (answer) {
            this.$refs[`postInput_${answer.name}`][0].value = answer.answer
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
h3 {
  margin-bottom: 1em;
}
.devotional {
  margin: 0 auto;

  @media(min-width: 768px) {
    padding: 4rem 2rem 3rem;
  }
}

.devotionalItem {
  margin-bottom: 2rem;
}

.devotionalVideo {
  margin-bottom: 3rem;
  position: relative;
  height: 0;
  padding-top: 56.25%;
}

.devotionalVideo::v-deep iframe {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.col1 {
  @media (min-width: 768px) {
    flex: 0 0 360px;
  }
}
</style>