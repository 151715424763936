<template>
  <transition name="slide-fade">
    <div v-if="show" class="message">
      {{ message }}
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    message: String,
    show: Boolean
  }
}
</script>

<style lang="scss" scoped>
.message {
  background-color: #C8FBD0;
  box-shadow: 2px 2px 1px rgba(50, 50, 50, 0.75);
  color: #777;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  text-align: right;
  padding: 1rem;
  z-index: 999;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
